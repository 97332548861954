import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { ROOT_URL } from '../../src/components/env';  // Uncomment if necessary
export const API_PATH = `${ROOT_URL}/server/api`;

const PostList = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${API_PATH}/posts.php?page=1`)
      .then(response => {
        const data = response.data;
        console.log('API response:', data); // Log the response to debug
        if (data && data.posts) {
          setPosts(data.posts);
        } else {
          throw new Error('Invalid API response structure');
        }
      })
      .catch(error => {
        console.error('There was a problem with the axios operation:', error);
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleVote = async (postId, voteType) => {
    try {
      await axios.post(`${API_PATH}/vote-post.php`, {
        post_id: postId,
        vote_type: voteType
      });
      // Refresh the post list or update the state to reflect changes
    } catch (error) {
      console.error('Failed to cast vote:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className='container'>
      <h1>Post List</h1>
      {posts.map(post => (
        <Card className='postcard' key={post.id}>
          <Card.Body>
            <Card.Title>{post.title}</Card.Title>
            <Card.Text className='two-line-title'>{post.content}</Card.Text>
            <p>Author: {post.author}</p>
            <p>Published on: {post.publish_date}</p>
            <p>Likes: {post.numLikes}, Dislikes: {post.numDislikes}</p>
            <Button variant="primary" onClick={() => navigate(`/post/${post.id}`)}>View detail</Button>
            <Button variant="success" onClick={() => handleVote(post.id, 'like')}>Like</Button>
            <Button variant="danger" onClick={() => handleVote(post.id, 'dislike')}>Dislike</Button>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default PostList;
