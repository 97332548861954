import React, { useState } from 'react';

const FormWithValidation = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [serverResponse, setServerResponse] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validation logic
  const validateForm = () => {
    let errors = {};

    if (!formData.name) {
      errors.name = 'Name is required';
    }

    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }

    if (!formData.message) {
      errors.message = 'Message is required';
    }

    return errors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true); // Set loading state
      try {
        const response = await fetch('https://aihelth.com/server/api/send_email.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams(formData),
        });

        const result = await response.json();

        if (result.status === 'success') {
          setServerResponse('Email sent successfully!');
           // Redirect to www.google.com
  window.location.href = 'https://www.google.com';
          setFormData({ name: '', email: '', message: '' }); // Reset form
        } else {
          setServerResponse(result.message);
        }
      } catch (error) {
        setServerResponse('An error occurred while sending the email.');
      } finally {
        setIsSubmitting(false); // Reset loading state
      }
    }
  };

  return (
    <div>
      <h2>Contact Us</h2>
      <div className='row d-flex justify-content-center align-items-center h-100'>
<div className='col-12 col-md-9 col-lg-7 col-xl-6'>
      <div className="card">
      <div className="card-body p-5">
      <form onSubmit={handleSubmit}>
      
         <div className='form-outline mb-4'>
          <input className="form-control form-control-lg"
            type="text"
            name="name"
             placeholder='Name'
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
          </div>

          <div className='form-outline mb-4'>
          <input className="form-control form-control-lg"
            type="email"
            name="email"
            placeholder='Email'
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
       
</div>
<div className='form-outline mb-4'>
          <textarea className="form-control form-control-lg"
            name="message"
             placeholder='Message'
            value={formData.message}
            onChange={handleChange}
          />
          {errors.message && <p style={{ color: 'red' }}>{errors.message}</p>}
       
</div>



        <button className='btn btn-success btn-block btn-lg gradient-custom-4 text-body' type="submit" disabled={isSubmitting}>Submit</button>
      </form>
      </div>
      </div>
      </div>
      </div>
      {serverResponse && <p>{serverResponse}</p>}
    </div>
  );
};

export default FormWithValidation;
