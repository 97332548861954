import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ROOT_URL } from '../../src/components/env';  // Uncomment if necessary
export const API_PATH = `${ROOT_URL}/server/api`;

function CreateAccount() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const validateForm = () => {
        if (!username.trim() || !password.trim() || !email.trim()) {
            setError("Please fill in all fields.");
            return false;
        }
        // Optional: Add more validation for email format, password strength, etc.
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        if (!validateForm()) return;

        setIsLoading(true);

        try {
            const response = await axios.post(`${API_PATH}/create-account.php`, {
                username,
                password,
                email
            });
            console.log(response.data);
            if (response.data.status === 'success') {
                navigate('/login');
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            console.error(error);
            setError('Failed to create account. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container mt-4">
            <h2>Create a New Account</h2>
            {error && <div className="alert alert-danger" role="alert">{error}</div>}
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="username" className="form-label">Username</label>
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? <span><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Creating account...</span> : 'Create Account'}
                </button>
            </form>
        </div>
    );
}

export default CreateAccount;
