import React, { useState } from 'react';

function ExtractEmail() {
    const [text, setText] = useState('');
    const [emails, setEmails] = useState([]);

    const handleOnChange = (event) => {
        setText(event.target.value);
    };

    const extractEmails = () => {
        const extractedEmails = text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
        setEmails(extractedEmails || []); // Set to an empty array if no emails are found
    };
    const handleDownloadAndSave = () => {
        downloadEmails();
        saveEmailsToDatabase();
    };

    const downloadEmails = () => {
        const emailText = emails.join('\n'); // Join emails by a new line
        const blob = new Blob([emailText], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);

        // Create a temporary anchor element to trigger download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'extracted-emails.txt'; // File name
        a.click();

        // Cleanup
        URL.revokeObjectURL(url);
    };

    const saveEmailsToDatabase = async () => {
        if (emails.length > 0) {
            try {
                const response = await fetch('https://aihelth.com/server/api/save_emails.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ emails }),
                });

                const result = await response.json();
                if (result.success) {
                    alert('Emails Download successfully!');
                } else {
                    alert('Failed to save emails: ' + result.message);
                }
            } catch (error) {
                console.error('Error saving emails:', error);
                alert('Error occurred while saving emails.');
            }
        } else {
            alert('No emails to save!');
        }
    };

    return (
        <>
            <div>
                <h1>Extract Emails</h1>
                <div className="mb-3">
                    <textarea
                        id="myBox"
                        value={text}
                        onChange={handleOnChange}
                        cols="30"
                        rows="10"
                        className="form-control"
                    ></textarea>
                </div>
                <button className="btn btn-primary my-3" onClick={extractEmails}>
                    Extract Emails
                </button>
                {emails.length > 0 && (
                    <>
                     
<button className="btn btn-success my-3" onClick={handleDownloadAndSave}>
    Download Emails 
</button>
                    </>
                )}
                <div className="mb-3">
                    <p>Emails: {emails.length > 0 ? emails.join(', ') : 'No emails found'}</p>
                </div>
            </div>
        </>
    );
}

export default ExtractEmail;
