import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ROOT_URL } from '../../src/components/env';  // Uncomment if necessary
export const API_PATH = `${ROOT_URL}/server/api`;

function ProfilePage() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        // Fetch user details when the component mounts
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(`${API_PATH}/getuserdetail.php`);
                if (response.data.status === 'success') {
                    setUsername(response.data.username);
                    setEmail(response.data.email);
                } else {
                    setError(response.data.message);
                }
            } catch (error) {
                setError('Failed to load user details. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserDetails();
    }, []);

    const handleUpdate = async (event) => {
        event.preventDefault();
        setError('');
        setSuccessMessage('');

        try {
            const response = await axios.post(`${API_PATH}/update-account.php`, {
                username,
                email
            });

            if (response.data.status === 'success') {
                setSuccessMessage('Profile updated successfully.');
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            setError('Failed to update profile. Please try again later.');
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mt-4">
            <h2>Your Profile</h2>
            {error && <div className="alert alert-danger" role="alert">{error}</div>}
            {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
            <form onSubmit={handleUpdate}>
                <div className="mb-3">
                    <label htmlFor="username" className="form-label">Username</label>
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">Update Profile</button>
            </form>
        </div>
    );
}

export default ProfilePage;
