import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import FroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';

const MyComponent = () => {
  const [newContent, setNewContent] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const handleInsert = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    // Example: Sending data to an API or updating state
    try {
      // Assume we're sending newContent to a server
      console.log('Submitted Content:', newContent);
      setMessage('Content inserted successfully!');
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div className="container">
      <h1>Insert About Us Content</h1>
      <Form onSubmit={handleInsert}>
        <Form.Group>
          <Form.Label>New About Us Content</Form.Label>
          <FroalaEditor
            tag="textarea"
            model={newContent}
            onModelChange={(content) => setNewContent(content)}
          />
        </Form.Group>
        <Button type="submit" variant="success">Insert Content</Button>
      </Form>
      {message && <p>{message}</p>}
      {error && <p>Error: {error.message}</p>}
    </div>
  );
};

export default MyComponent;
