import React, { useState } from 'react';

function ExtractEmail() {
    const [text, setText] = useState('');
    const [emails, setEmails] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [city, setCity] = useState('');
    const [searchEmail, setSearchEmail] = useState('@gmail.com');

    const handleOnChange = (event) => {
        setText(event.target.value);
    };

    const extractEmails = () => {
        const extractedEmails = text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
        setEmails(extractedEmails || []); // Set to an empty array if no emails are found
    };

    const handleKeywordChange = (event) => {
        setKeyword(event.target.value);
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const handleSearchEmailChange = (event) => {
        setSearchEmail(event.target.value);
    };

    const handleDownloadAndSave = () => {
        downloadEmails();
        saveEmailsToDatabase();
    };

    const downloadEmails = () => {
        const emailText = emails.join('\n'); // Join emails by a new line
        const blob = new Blob([emailText], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);

        // Create a temporary anchor element to trigger download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'extracted-emails.txt'; // File name
        a.click();

        // Cleanup
        URL.revokeObjectURL(url);
    };

    const saveEmailsToDatabase = async () => {
        if (emails.length > 0) {
            try {
                const response = await fetch('https://aihelth.com/server/api/save_emails.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ emails, keyword, city, searchEmail }),
                });

                const result = await response.json();
                if (result.success) {
                    alert('Emails Download successfully!');
                } else {
                    alert('Failed to save emails: ' + result.message);
                }
            } catch (error) {
                console.error('Error saving emails:', error);
                alert('Error occurred while saving emails.');
            }
        } else {
            alert('No emails to save!');
        }
    };

    const handleGoogleSearch = () => {
        const query = `"${searchEmail}" "${keyword}" "${city}"`; 
        const googleSearchUrl = `https://www.google.com/search?q=${encodeURIComponent(query)}`;
        window.open(googleSearchUrl, '_blank'); // Open in a new tab
    };

    return (
        <>
            <div className='row'>
                <h1>Extract Emails</h1>

                {/* Email Domain Field */}
                <div className="mb-3 col-md-4">
                    <label>Email Domain:</label>
                    <input
                        value={searchEmail}
                        onChange={handleSearchEmailChange}
                        className="form-control"
                        placeholder="e.g. @gmail.com"
                    />
                </div>

                {/* Keyword Dropdown */}
                <div className="mb-3 col-md-4">
                    <label>Keyword:</label>
                    <select value={keyword} onChange={handleKeywordChange} className="form-control">
    <option value="">Select Keyword</option>
    <option value="school">School</option>
    <option value="business">Business</option>
    <option value="organization">Organization</option>
    <option value="education">Education</option>
    <option value="consulting">Consulting</option>
    <option value="technology">Technology</option>
    <option value="software">Software</option>
    <option value="finance">Finance</option>
    <option value="banking">Banking</option>
    <option value="healthcare">Healthcare</option>
    <option value="medical">Medical</option>
    <option value="real estate">Real Estate</option>
    <option value="construction">Construction</option>
    <option value="insurance">Insurance</option>
    <option value="marketing">Marketing</option>
    <option value="advertising">Advertising</option>
    <option value="retail">Retail</option>
    <option value="ecommerce">E-commerce</option>
    <option value="manufacturing">Manufacturing</option>
    <option value="transportation">Transportation</option>
    <option value="logistics">Logistics</option>
    <option value="automotive">Automotive</option>
    <option value="telecommunication">Telecommunication</option>
    <option value="hospitality">Hospitality</option>
    <option value="tourism">Tourism</option>
    <option value="travel">Travel</option>
    <option value="energy">Energy</option>
    <option value="oil and gas">Oil and Gas</option>
    <option value="mining">Mining</option>
    <option value="agriculture">Agriculture</option>
    <option value="pharmaceutical">Pharmaceutical</option>
    <option value="legal">Legal</option>
    <option value="law firm">Law Firm</option>
    <option value="accounting">Accounting</option>
    <option value="taxation">Taxation</option>
    <option value="recruitment">Recruitment</option>
    <option value="human resources">Human Resources</option>
    <option value="training">Training</option>
    <option value="startup">Startup</option>
    <option value="venture capital">Venture Capital</option>
    <option value="investment">Investment</option>
    <option value="nonprofit">Nonprofit</option>
    <option value="charity">Charity</option>
    <option value="media">Media</option>
    <option value="public relations">Public Relations</option>
    <option value="printing">Printing</option>
    <option value="fashion">Fashion</option>
    <option value="apparel">Apparel</option>
    <option value="cosmetics">Cosmetics</option>
    <option value="beauty">Beauty</option>
    <option value="entertainment">Entertainment</option>
    <option value="music">Music</option>
    <option value="film">Film</option>
    <option value="television">Television</option>
    <option value="sports">Sports</option>
    <option value="fitness">Fitness</option>
    <option value="wellness">Wellness</option>
    <option value="food and beverage">Food and Beverage</option>
    <option value="restaurant">Restaurant</option>
    <option value="catering">Catering</option>
    <option value="grocery">Grocery</option>
    <option value="event management">Event Management</option>
    <option value="cleaning services">Cleaning Services</option>
    <option value="security">Security</option>
    <option value="maintenance">Maintenance</option>
    <option value="electronics">Electronics</option>
    <option value="engineering">Engineering</option>
    <option value="architecture">Architecture</option>
    <option value="design">Design</option>
    <option value="interior design">Interior Design</option>
    <option value="furniture">Furniture</option>
    <option value="IT services">IT Services</option>
    <option value="cybersecurity">Cybersecurity</option>
    <option value="cloud computing">Cloud Computing</option>
    <option value="data analytics">Data Analytics</option>
    <option value="AI">AI</option>
    <option value="machine learning">Machine Learning</option>
    <option value="blockchain">Blockchain</option>
    <option value="cryptocurrency">Cryptocurrency</option>
    <option value="web development">Web Development</option>
    <option value="mobile development">Mobile Development</option>
    <option value="digital marketing">Digital Marketing</option>
    <option value="SEO">SEO</option>
    <option value="social media">Social Media</option>
    <option value="content creation">Content Creation</option>
    <option value="copywriting">Copywriting</option>
    <option value="graphic design">Graphic Design</option>
    <option value="video production">Video Production</option>
    <option value="photography">Photography</option>
    <option value="public speaking">Public Speaking</option>
    <option value="coaching">Coaching</option>
    <option value="leadership">Leadership</option>
    <option value="project management">Project Management</option>
    <option value="supply chain">Supply Chain</option>
    <option value="import-export">Import-Export</option>
    <option value="distribution">Distribution</option>
    <option value="warehousing">Warehousing</option>
</select>

                </div>

                {/* City Dropdown */}
                <div className="mb-3 col-md-4">
                    <label>City:</label>
                    <select value={city} onChange={handleCityChange} className="form-control">
    <option value="">Select City</option>
    <option value="Agra">Agra</option>
    <option value="Aligarh">Aligarh</option>
    <option value="Allahabad">Allahabad</option>
    <option value="Amroha">Amroha</option>
    <option value="Ayodhya">Ayodhya</option>
    <option value="Azamgarh">Azamgarh</option>
    <option value="Bareilly">Bareilly</option>
    <option value="Bijnor">Bijnor</option>
    <option value="Bulandshahr">Bulandshahr</option>
    <option value="Etawah">Etawah</option>
    <option value="Firozabad">Firozabad</option>
    <option value="Ghaziabad">Ghaziabad</option>
    <option value="Gorakhpur">Gorakhpur</option>
    <option value="Jhansi">Jhansi</option>
    <option value="Kanpur">Kanpur</option>
    <option value="Lucknow">Lucknow</option>
    <option value="Mathura">Mathura</option>
    <option value="Meerut">Meerut</option>
    <option value="Moradabad">Moradabad</option>
    <option value="Muzaffarnagar">Muzaffarnagar</option>
    <option value="Noida">Noida</option>
    <option value="Prayagraj">Prayagraj</option>
    <option value="Saharanpur">Saharanpur</option>
    <option value="Sitapur">Sitapur</option>
    <option value="Varanasi">Varanasi</option>
</select>

                </div>

                {/* Google Search Button */}
                <button className="btn btn-primary my-3" onClick={handleGoogleSearch}>
                    Search on Google
                </button>

                {/* Text Area for Pasting Content */}
                <div className="mb-3">
                    <textarea
                        id="myBox"
                        value={text}
                        onChange={handleOnChange}
                        cols="30"
                        rows="10"
                        className="form-control"
                        placeholder="Paste content here to extract emails"
                    ></textarea>
                </div>

                {/* Extract Emails Button */}
                <button className="btn btn-primary my-3" onClick={extractEmails}>
                    Extract Emails
                </button>

                {emails.length > 0 && (
                    <>
                        {/* Download and Save Emails Button */}
                        <button className="btn btn-success my-3" onClick={handleDownloadAndSave}>
                            Download Emails
                        </button>
                    </>
                )}

                {/* Display Extracted Emails */}
                <div className="mb-3">
                    <p>Emails: {emails.length > 0 ? emails.join(', ') : 'No emails found'}</p>
                </div>
            </div>
        </>
    );
}

export default ExtractEmail;
