import React, { useRef, useState } from 'react';
import { toPng } from 'html-to-image';
import download from 'downloadjs';

const BusinessCard = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [website, setWebsite] = useState('');
    const [address, setAddress] = useState('');
    const [designation, setDesignation] = useState(''); // New state for designation
    const cardRef = useRef(null);

    const downloadCard = () => {
        if (cardRef.current === null) {
            return;
        }

        toPng(cardRef.current)
            .then((dataUrl) => {
                download(dataUrl, 'business-card.png');
            })
            .catch((err) => {
                console.error('Error downloading card:', err);
            });
    };

    return (
        <div>
            <div style={{ marginBottom: '10px' }}>
                <label>Name: </label>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter name"
                />
            </div>

            <div style={{ marginBottom: '10px' }}>
                <label>Email: </label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter email"
                />
            </div>

            <div style={{ marginBottom: '10px' }}>
                <label>Phone: </label>
                <input
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Enter phone number"
                />
            </div>

            <div style={{ marginBottom: '10px' }}>
                <label>Website: </label>
                <input
                    type="text"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    placeholder="Enter website"
                />
            </div>

            <div style={{ marginBottom: '10px' }}>
                <label>Address: </label>
                <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Enter address"
                />
            </div>

            <div style={{ marginBottom: '10px' }}>
                <label>Designation: </label>
                <input
                    type="text"
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                    placeholder="Enter designation"
                />
            </div>

            <div ref={cardRef} style={{ display: 'inline-block', padding: '20px', border: '1px solid #ccc' }}>
                <div style={{ background: 'blue', color: 'white', padding: '10px' }}>
                    <h3>{username ? username : 'GRACE TAYLOR'}</h3> {/* Displays entered username or fallback */}
                    <p>{designation ? designation : 'Regional Manager'}</p> {/* Displays entered designation or fallback */}
                    <p>Phone: {phone ? phone : '516 688 7372'}</p> {/* Displays entered phone or fallback */}
                    <p>Email: {email ? email : 'example@company.com'}</p> {/* Displays entered email or fallback */}
                    <p>Website: {website ? website : 'www.companyname.com'}</p> {/* Displays entered website or fallback */}
                    <p>Address: {address ? address : '123 Main Street, New York, NY 10001'}</p> {/* Displays entered address or fallback */}
                </div>
            </div>

            <button onClick={downloadCard}>Download Card</button>
        </div>
    );
};

export default BusinessCard;
