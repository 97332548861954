import React from 'react';
import BusinessCard from '../components/BusinessCard'; // Adjust the path if needed

const Card = () => {
    return (
        <div>
            <h1>Business Card Page</h1>
            {/* Render the BusinessCard component */}
            <BusinessCard />
        </div>
    );
};

export default Card;
